const React = require('react');

const ONE_MINUTE_IN_MS = 60 * 1000;
const ONE_HOUR_IN_MS = ONE_MINUTE_IN_MS * 60;
const ONE_DAY_IN_MS = ONE_HOUR_IN_MS * 24;

const styles = {
  container: {
    whiteSpace: "nowrap",
  },
};

function quot(value, step) {
  return (value - (value % step)) / step;
}

function timeSpanReadable(absTimeDiffInMS) {
  if (absTimeDiffInMS < ONE_MINUTE_IN_MS) {
    return '< 1분';
  }
  if (absTimeDiffInMS < ONE_HOUR_IN_MS) {
    return quot(absTimeDiffInMS, ONE_MINUTE_IN_MS) + '분';
  }
  if (absTimeDiffInMS < ONE_DAY_IN_MS) {
    return quot(absTimeDiffInMS, ONE_HOUR_IN_MS) + '시간';
  }
  return quot(absTimeDiffInMS, ONE_DAY_IN_MS) + '일';
}

function RelativeTime({ time }) {
  if (time == null || !(time instanceof Date)) {
    return null;
  }
  const timeDiffInMS = time.valueOf() - Date.now();
  const isBefore = (timeDiffInMS < 0);
  const beforeAfterStr = isBefore ? '전' : '후';
  const timeSpanStr = timeSpanReadable(Math.abs(timeDiffInMS));
  return (
    <div
      style={styles.container}
      title={time.toLocaleString()}>
      <span>{timeSpanStr} {beforeAfterStr}</span>
      <br />
      <span style={{ fontSize: "0.8em", color: "#ccc" }}>{time.toLocaleDateString()}</span>
    </div>
  );
}

module.exports = RelativeTime;
