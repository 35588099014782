var React = require("react");
const createReactClass = require('create-react-class');
var withRouter = require("react-router").withRouter;

var Link = require("react-router-dom").Link;

var parseQuery = require("../util.js").parseQuery;
import LoginMethod from "../comps/LoginMethod";
const TeamMode = require("../comps/TeamMode.js");
const AdsList = require("../comps/AdsList.js");
const Country = require("../comps/Country.js");
const api = require("../api.js");
const RelativeTime = require("../comps/RelativeTime.js");

var limit = 100;

var UserList = createReactClass({
  getInitialState: function() {
    return {
      loading: false,
      items: [],
    };
  },

  load: function() {
    const param = parseQuery(this.props.history.location.search);

    this.setState({
      loading: true,
    });

    api.get("/api/users", {
      offset: param.offset || 0,
      limit: limit,
      q: param.q || "",
      ads: param.ads || "",
    })
      .then((data) => {
        this.setState({
          items: data.items,
        });
      }, (err) => {
        alert(err.message);
      })
      .then(() => {
        this.setState({
          loading: false,
        });
      });
  },

  componentDidMount: function() {
    this.load();
  },

  componentWillReceiveProps: function(nextProps) {
    if (this.props.location.search != nextProps.history.location.search) {
      this.load();
    }
  },

  renderTeam(team) {
    return (
      <React.Fragment>
        <td>
          <TeamMode mode={team.mode} />
        </td>
        <td>
          <Link to={"/team/" + team.id}>
            <button className="pure-button">
              {team.name}
            </button>
          </Link>
        </td>
        <td>
          <span>{(team.product_count < 100) ? team.product_count : "99+"}</span>
        </td>
      </React.Fragment>
    );
  },

  renderTeamPlaceholder() {
    return (
      <td colSpan={3}>
        No teams
      </td>
    )
  },

  renderUserWithFirstTeam(user, teamCount, firstTeam) {
    const rowSpan = teamCount > 1 ? teamCount : null;

    return (
      <tr key={user.id}>
        <td rowSpan={rowSpan}>{user.id}</td>
        <td rowSpan={rowSpan}>
          <RelativeTime
            time={new Date(user.created_time)}
          />
        </td>
        <td rowSpan={rowSpan}>
          <a href={user.profile_img} target="_blank">
            <img
              src={user.profile_img}
              style={{ width: "50px", height: "50px" }}
            />
          </a>
        </td>
        <td rowSpan={rowSpan}>{user.name}</td>
        <td rowSpan={rowSpan}>
          <LoginMethod user={user} />
        </td>
        <td rowSpan={rowSpan}>
          <AdsList
            ads={user.ads}
          />
        </td>
        {firstTeam
         ? this.renderTeam(firstTeam)
         : this.renderTeamPlaceholder()}
        <td rowSpan={rowSpan}>
          <Country
            code={user.country_code}
            name={user.country_name}
          />
        </td>
        <td rowSpan={rowSpan}>
          <RelativeTime
            time={new Date(user.last_login)}
          />
        </td>
      </tr>
    );
  },

  renderUser: function(user, i) {
    const teamCount = user.teams ? user.teams.length : 0;
    const firstTeam = teamCount > 0 ? user.teams[0] : null;
    const restTeams = user.teams ? user.teams.slice(1) : [];
    return (
      <React.Fragment key={user.id}>
        {this.renderUserWithFirstTeam(user, teamCount, firstTeam)}
        {restTeams.map(function (team, idx) {
          return (
            <tr key={user.id + "#" + idx}>
              {this.renderTeam(team)}
            </tr>
          );
        }.bind(this))}
      </React.Fragment>
    );
  },

  push: function(param) {
    this.props.history.push(
      this.props.history.location.pathname + "?" + $.param(param)
    );
  },

  onSearch: function(e) {
    // https://github.com/ReactTraining/react-router/issues/1933
    // chrome 이 #/ 앞에 ?를 붙여서 페이지 새로고침이 발생한다.
    e.preventDefault();

    const query = {};
    const q = this.refs.q.value;
    if (q && q != "") {
      query.q = q;
    }
    const ads = this.refs.ads.value;
    if (ads && ads != "") {
      query.ads = ads;
    }
    this.push(query);
  },

  onNavigate: function (navOffset) {
    const param = parseQuery(this.props.history.location.search);

    const query = {};
    if (param.q && param.q != "") {
      query.q = param.q;
    }

    if (param.ads && param.ads != "") {
      query.ads = param.ads;
    }

    const offset = Math.max(0, (parseInt(param.offset) || 0) + navOffset);
    if (offset != 0) {
      query.offset = offset;
    }

    this.push(query);
  },

  onPrev: function() {
    this.onNavigate(-limit);
    window.scrollTo(0, 0);
  },

  onNext: function() {
    this.onNavigate(limit);
    window.scrollTo(0, 0);
  },

  render: function() {
    return (
      <div>
        <form className="pure-form">
          <select ref="ads" onChange={this.onSearch}>
            <option value="">광고</option>
            <option value="google">Google</option>
            <option value="naver">Naver</option>
            <option value="kakao">Kakao</option>
            <option value="googleads">Google Ads</option>
            <option value="facebook">Facebook</option>
            <option value="apple">Apple</option>
          </select>
          <input type="text" className="pure-input-rounded" ref="q" />
          <button type="submit" className="pure-button" onClick={this.onSearch}>
            검색
          </button>
          {this.state.loading &&
           (<img
              src="https://upload.wikimedia.org/wikipedia/commons/c/cd/Vector_Loading_fallback.gif"
              style={{verticalAlign: "middle"}}
              alt="loading" />)}
        </form>
        <table className="pure-table pure-table-bordered">
          <thead>
            <tr className="stickyHeader">
              <th>#</th>
              <th>가입일</th>
              <th>사진</th>
              <th>이름</th>
              <th>로그인</th>
              <th>광고</th>
              <th>모드</th>
              <th>팀명</th>
              <th>제품 수</th>
              <th>국가</th>
              <th>마지막 로그인</th>
            </tr>
          </thead>
          <tbody>{this.state.items.map(this.renderUser)}</tbody>
        </table>

        <div>
          <button className="pure-button" onClick={this.onPrev}>
            이전 페이지
          </button>
          &nbsp;
          <button className="pure-button" onClick={this.onNext}>
            다음 페이지
          </button>
        </div>
      </div>
    );
  }
});

module.exports = withRouter(UserList);
