
function parseQuery(qstr) {
    var query = {};
    var a = (qstr[0] === '?' ? qstr.substr(1) : qstr).split('&');
    for (var i = 0; i < a.length; i++) {
      var b = a[i].split('=');
      var value = (b[1] || '').replace(/[+]/g, '%20');
      query[decodeURIComponent(b[0])] = decodeURIComponent(value);
    }
    return query;
}

module.exports = {
  parseQuery: parseQuery,
}
