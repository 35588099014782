const React = require("react");
const createReactClass = require('create-react-class');
const withRouter = require("react-router").withRouter;
const Link = require("react-router-dom").Link;
const util = require("../util.js");
const TeamMode = require("../comps/TeamMode.js");
const TzInfo = require("../comps/TzInfo.js");
const api = require("../api.js");
const RelativeTime = require("../comps/RelativeTime.js");

const limit = 100;

const CanceledTeamList = createReactClass({
  getInitialState: function() {
    return {
      loading: false,
      items: [],
    };
  },

  load: function() {
    var param = util.parseQuery(this.props.history.location.search);

    this.setState({
      loading: true,
    });

    api.get("/api/teams/canceled", {
      offset: param.offset || 0,
      limit: limit,
      q: param.q || ""
    })
      .then((data) => {
        this.setState({ items: data.items });
      }, (err) => {
        alert(err.message);
      })
      .then(() => {
        this.setState({
          loading: false,
        });
      });
  },

  componentDidMount: function() {
    this.load();
  },

  componentWillReceiveProps: function(nextProps) {
    if (this.props.location.search != nextProps.history.location.search) {
      this.load();
    }
  },

  push: function(param) {
    this.props.history.push(
      this.props.history.location.pathname + "?" + $.param(param)
    );
  },

  onSearch: function(e) {
    // https://github.com/ReactTraining/react-router/issues/1933
    // chrome 이 #/ 앞에 ?를 붙여서 페이지 새로고침이 발생한다.
    e.preventDefault();
    this.push({ q: this.refs.q.value });
  },

  onNavigate: function (navOffset) {
    const param = util.parseQuery(this.props.history.location.search);

    const query = {};
    if (param.q && param.q != "") {
      query.q = param.q;
    }

    const offset = Math.max(0, (parseInt(param.offset) || 0) + navOffset);
    if (offset != 0) {
      query.offset = offset;
    }

    this.push(query);
  },

  onPrev: function() {
    this.onNavigate(-limit);
    window.scrollTo(0, 0);
  },

  onNext: function() {
    this.onNavigate(limit);
    window.scrollTo(0, 0);
  },

  renderRow: function(team, i) {
    const trialEnd = team.trial_end && new Date(team.trial_end);
    const isTrial = trialEnd && (trialEnd > Date.now());
    return (
      <tr key={i}>
        <td>{team.id}</td>
        <td>
          <RelativeTime
            time={new Date(team.subs_canceled_at)}
          />
        </td>
        <td>
          <RelativeTime
            time={new Date(team.subs_created)}
          />
        </td>
        <td>{team.plan}</td>
        <td>
          <Link to={"/team/" + team.id}>
            <button className="pure-button">
              {team.name}
            </button>
          </Link>
        </td>
        <td>
          <TeamMode
            mode={team.mode}/>
        </td>
        <td>{team.member_count}</td>
        <td>{(team.product_count < 100) ? team.product_count : "99+"}</td>
        <td>
          <RelativeTime
            time={new Date(team.created_time)}
          />
        </td>
        <td>
          <span style={isTrial ? null : {color: "silver"}}>
            <RelativeTime
              time={trialEnd}
            />
          </span>
        </td>
        <td>{team.created_by_ads_user ? "생성자" : team.has_ads_user ? "맴버" : null}</td>
        <td>
          {team.company_name}
        </td>
        <td>
          <TzInfo
            name={team.tz_name}
            offsetMin={team.tz_offset_min}
            twoLine={true}
          />
        </td>
      </tr>
    );
  },

  render: function() {
    return (
      <div>
        <form className="pure-form">
          <input type="text" className="pure-input-rounded" ref="q" />
          <button type="submit" className="pure-button" onClick={this.onSearch}>
            검색
          </button>
          {this.state.loading &&
           (<img
              src="https://upload.wikimedia.org/wikipedia/commons/c/cd/Vector_Loading_fallback.gif"
              style={{verticalAlign: "middle"}}
              alt="loading" />)}
        </form>
        <table className="pure-table pure-table-bordered">
          <thead>
            <tr>
              <th>#</th>
              <th>결제해지일</th>
              <th>결제등록일</th>
              <th>결제</th>
              <th>이름</th>
              <th>모드</th>
              <th>멤버수</th>
              <th>제품수</th>
              <th>생성일</th>
              <th>체험종료</th>
              <th>광고</th>
              <th>회사명</th>
              <th>TZ</th>
            </tr>
          </thead>
          <tbody>{this.state.items.map(this.renderRow)}</tbody>
        </table>

        <div>
          <button className="pure-button" onClick={this.onPrev}>
            이전 페이지
          </button>
          &nbsp;
          <button className="pure-button" onClick={this.onNext}>
            다음 페이지
          </button>
        </div>
      </div>
    );
  }
});

module.exports = withRouter(CanceledTeamList);
