const React = require("react");
const createReactClass = require("create-react-class");
import firebase from "firebase/app";

const Link = require("react-router-dom").Link;
const Router = require("react-router-dom").HashRouter;
const Route = require("react-router-dom").Route;

const TeamList = require("./pages/TeamList.js");
const UserList = require("./pages/UserList.js");
const TeamDetail = require("./pages/TeamDetail.js");
const PayedTeamList = require("./pages/PayedTeamList.js");
const MayPayTeamList = require("./pages/MayPayTeamList.js");
const CanceledTeamList = require("./pages/CanceledTeamList.js");
const NoticeList = require("./pages/NoticeList.jsx");
import biPng from "./img/bi.png";
import AdminUserList from "./pages/AdminUserList";

function UserInfo({ role }) {
  return (
    <div style={{ marginTop: 30 }}>
      <div>{firebase.auth().currentUser.displayName}</div>
      <div>{role}</div>
      <button
        className="pure-button"
        onClick={() =>
          firebase
            .auth()
            .signOut()
            .then(() => window.location.replace("/"))
        }
      >
        Sign-out
      </button>
    </div>
  );
}

function MenuItem({ title, link }) {
  return (
    <li className="pure-menu-item">
      <Link to={link} className="pure-menu-link">
        {title}
      </Link>
    </li>
  );
}

function Menu() {
  return (
    <div className="pure-menu">
      <ul className="pure-menu-list">
        <MenuItem title="팀 목록" link="/team" />
        <MenuItem title="유저 목록" link="/user" />
        <MenuItem title="[결제] 팀 목록" link="/payed-team" />
        <MenuItem title="공지사항" link="/notice" />
      </ul>
    </div>
  );
}

function AdminMenu() {
  return (
    <div className="pure-menu">
      <h4>ADMIN:</h4>
      <ul className="pure-menu-list">
        <MenuItem title="관리자 목록" link="/admin-user" />
      </ul>
    </div>
  );
}

const App = createReactClass({
  getInitialState: function () {
    return { active: "" };
  },

  toggle: function () {
    if (this.state.active) {
      this.setState({ active: "" });
    } else {
      this.setState({ active: "active" });
    }
  },

  close: function () {
    this.setState({ active: "" });
  },

  render: function () {
    const role = this.props.role;
    const isAdmin = role === "admin";
    return (
      <Router>
        <div id="layout" className={this.state.active}>
          <a id="menuLink" className="menu-link" onClick={this.toggle}>
            <span></span>
          </a>

          <div id="menu" className={this.state.active}>
            <div style={{ textAlign: "center", padding: "10px" }}>
              <img src={biPng} style={{ width: "80px" }} />
            </div>

            <Menu />

            <UserInfo role={role} />

            {isAdmin && <AdminMenu />}
          </div>
          <div id="main" onClick={this.close} className={this.state.active}>
            <Route exact path="/" component={TeamList} />
            <Route exact path="/team" component={TeamList} />
            <Route exact path="/payed-team" component={PayedTeamList} />
            <Route exact path="/may-pay-team" component={MayPayTeamList} />
            <Route exact path="/canceled-team" component={CanceledTeamList} />
            <Route exact path="/user" component={UserList} />
            <Route exact path="/team/:team_id" component={TeamDetail} />
            <Route exact path="/notice" component={NoticeList} />
            {/* */}
            <Route exact path="/admin-user" component={AdminUserList} />
          </div>
        </div>
      </Router>
    );
  },
});

export default App;
